import React from 'react';

const Logos = () => {
  return (
    <div className="logos-group">
      <div className="logos-group-item">
        <img src='images/landing/img_healthiest.png' alt="partners" />
      </div>
      <span className='logos-group-border'></span>
      <div className="logos-group-item">
        <img src='images/landing/img_perceivant.png' alt="partners" />
      </div>
      <span className='logos-group-border'></span>
      <div className="logos-group-item">
        <img src='images/landing/img_appMedia.png' alt="partners" />
      </div>
      <span className='logos-group-border'></span>
      <div className="logos-group-item">
        <img src='images/landing/img_ernit.png' alt="partners" />
      </div>
    </div>
  )
}

export default Logos
