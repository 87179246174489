import React from "react";

import "../stylesheets/feater/global.css";
import "../stylesheets/feater/header.css";
import "../stylesheets/feater/employees1.css";
import "../stylesheets/feater/task.css";
import "../stylesheets/feater/employees2.css";
import "../stylesheets/feater/culture.css";
import "../stylesheets/feater/companySystem.css";

import Header from "./header";
import Employees1 from "./employees-1";
import CompanySystem from "./companySystem";
import Task from "./task";
import Employees2 from "./employees-2";
import Culture from "./culture";
import Footer from "./footer";

const Feather = () => {
  return (
    <div className="page-container">
      <div id="body">
        <Header />
        <Employees1 />
        <CompanySystem />
        <Task />
        <Employees2 />
        <Culture />
        <Footer />
      </div>
    </div>
  );
};

export default Feather;
