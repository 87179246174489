import React from "react";

const Culture = () => {
  return (
    <>
      <div id="section-culture">
        <div className="section section-culture-1">
          <img
            src="images/feather/culture/culture_icon.svg"
            alt="employees logo icon"
            className="employees1-icon"
          />
          <div className="first-text">
            <h2>Corporate culture</h2>
          </div>
          <div className="second-text">
            <h2>
              Unlock the secret to productivity and resilience with our vibrant
              corporate culture and nurturing internal atmosphere. We cherish
              our employees' feedback, seeing it as an essential tool to
              maintain harmony and drive productivity. With our intuitive
              system, everyone can readily identify available time slots for
              meetings, allowing swift resolution of work matters without
              encroaching on other tasks. At PettersonApps, we don't just foster
              a work culture; we cultivate a thriving community that values
              work-life balance."
            </h2>
          </div>
        </div>
        {/* <div className="section section-culture-2">
          <div className="board-1">
            <div className="section-culture-2-text">
              <h2>Projects teams management</h2>
              <h3>
                Managing a large project team can be a complex task, but with
                WIT, it's a breeze. We bring clarity to complexity, ensuring
                effective coordination and seamless collaboration, regardless of
                the size of your team. Because when it comes to project
                management, we believe more is merrier, not messier.
              </h3>
            </div>
          </div>
          <div className="board-2">
            <div className="section-culture-2-text">
              <h2>Employees CV generation for sales</h2>
              <h3>
                As each task is successfully completed, our employees' value to
                the company and our esteemed customers grows exponentially.
                Every achievement adds a unique sparkle to our employees' CV,
                empowering them to become cherished assets for the organization
                and the clients we serve.
              </h3>
            </div>
          </div>
        </div> */}
        <div className="section section-culture-3">
          <div className="container">
            <div className="section-culture-3-text">
              <h2>Q&A - internal psychological climate</h2>
              <h3>
                In the dynamic world of IT, we're prepared for force majeure
                events and tight deadlines that call for extra dedication. Our
                robust overwork management system plays a critical role in
                making swift and informed decisions, ensuring the well-being of
                our workforce while maintaining our commitment to deliver timely
                results.
              </h3>
            </div>
            <div className="section-culture-3-image">
              <div className="images">
                <img
                  src="images/feather/culture/culture_qa.png"
                  alt="feather mockup"
                  className="header-mockup-1"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section section-culture-4">
          <div className="container">
            <div className="section-culture-4-text">
              <h2>Employees feedbacks</h2>
              <h3>
                We believe in the transformative power of feedback. Not only
                does it spur our company's continuous improvement, but it also
                fosters our employees' personal growth. At WIT, feedback isn't
                just a tool; it's the fuel that drives us towards excellence.
              </h3>
            </div>
            <div className="section-culture-4-image">
              <div className="images">
                <img
                  src="images/feather/culture/culture_employees.png"
                  alt="feather mockup"
                  className="header-mockup-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Culture;
