import React from "react";

const Task = () => {
  return (
    <>
      <div id="section-task">
        <div className="section section-task-1">
          <img
            src="images/feather/task/task_icon.svg"
            alt="employees logo icon"
            className="employees1-icon"
          />
          <div className="first-text">
            <h2>Task and time management</h2>
          </div>
          <div className="second-text">
            <h2>
              Welcome to a new era of task and time management. Right from
              onboarding, each employee is assigned a unique profile within our
              cutting-edge CMS. As they hone their skills and gain experience,
              their internal resume evolves, granting managers a clear
              perspective of their value to our esteemed customers. We uphold a
              culture of trust in our relationships with employees, making
              reporting a breeze with our user-friendly app. In just two clicks,
              work reports are dispatched, epitomizing simplicity and
              efficiency.
            </h2>
          </div>
        </div>
        <div className="section section-task-2">
          <div className="container">
            <div className="section-task-2-text">
              <h2>Range (employees database)</h2>
              <h3>
                Experience the power of information at your fingertips with
                Range, our comprehensive employees' database. Every key detail
                about every employee, now accessible in a single view.
                Effortlessly search, sort, and mark with our user-friendly
                interface. Range - your ultimate tool for seamless human
                resources management.
              </h3>
            </div>
            <div className="section-task-2-image">
              <div className="images">
                <img
                  src="images/feather/task/task_range.png"
                  alt="feather mockup"
                  className="header-mockup-1"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section section-task-3">
          <div className="board-1">
            <div className="section-task-3-text">
              <h2>Employees onboarding</h2>
              <h3>
                Smoothly welcome your new hires with our streamlined onboarding
                process. Easily obtain all the necessary permissions while
                ensuring that their detailed resumes stay up-to-the-minute. With
                us, onboarding is not just a process, it's a seamless beginning
                to a productive journey.
              </h3>
            </div>
            <div className="section-task-3-image">
              <div className="images">
                <img
                  src="images/feather/task/task_employees_onboarding.png"
                  alt="feather mockup"
                  className="header-mockup-1"
                />
              </div>
            </div>
          </div>
          <div className="board-2">
            <div className="section-task-3-text">
              <h2>Employees technologies and skills</h2>
              <h3>
                Uncover the wealth of each employee's expertise with our
                in-depth skills and technologies tracking. Gain insight into the
                main technologies your team uses and the unique skills they
                bring to the table.
              </h3>
            </div>
            <div className="section-task-3-image">
              <div className="images">
                <img
                  src="images/feather/task/task_employees_technical.png"
                  alt="feather mockup"
                  className="header-mockup-1"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section section-task-4">
          <div className="container">
            <div className="section-task-4-text">
              <h2>Vacations management</h2>
              <h3>
                Embrace the balance between work and leisure with our efficient
                vacations management system. Recognizing the importance of time
                off, we offer an easy way to track and schedule vacations,
                ensuring minimal disruption to project timelines. Experience
                hassle-free planning and execution that truly respects the needs
                of your employees.
              </h3>
            </div>
            <div className="section-task-4-image">
              <div className="images">
                <img
                  src="images/feather/task/task_vacation_management.png"
                  alt="feather mockup"
                  className="header-mockup-1"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section section-task-5">
          <div className="board-1">
            <div className="section-task-5-text">
              <h2>Workload reports</h2>
              <h3>
                Experience efficiency like never before with our workload
                reports. In just two clicks, employees can display their
                workload or highlight their availability for new projects.
                Streamlined reporting, redefined for a smarter, faster work
                environment.
              </h3>
            </div>
            <div className="section-task-5-image">
              <div className="images">
                <img
                  src="images/feather/task/task_reports.png"
                  alt="feather mockup"
                  className="mockup-1"
                />
              </div>
            </div>
          </div>
          <div className="board-2">
            <div className="section-task-5-text">
              <h2>Holidays</h2>
              <h3>
                Holidays are not just mere "vacations"; they are transformative
                moments that beautifully synchronize with the timing of your
                projects.
              </h3>
            </div>
            <div className="section-task-5-image">
              <div className="images">
                <img
                  src="images/feather/task/task_holidays.png"
                  alt="feather mockup"
                  className="mockup-2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section section-task-6">
          <div className="section-task-6-text">
            <h2>Employees workload timelines </h2>
            <h3>
              Unlock the power of employee workload timelines and revolutionize
              your team management! These dynamic and efficient tools provide
              managers with the ultimate solution to effortlessly track,
              monitor, and evaluate the workload assigned to their valuable team
              members. Seamlessly visualize task distribution and gain a
              comprehensive understanding of individual employee capacity. With
              these indispensable timelines at your disposal, you can
              proactively identify periods of excessive workload and mitigate
              the risk of burnout. Moreover, leverage the predictive
              capabilities of these timelines to optimize task allocation and
              ensure consistent productivity, guaranteeing a harmonious balance
              between work and downtime. Elevate your team's performance to new
              heights with employee workload timelines!
            </h3>
          </div>
          <div className="section-task-6-image">
            <div className="images">
              <img
                src="images/feather/task/product_plan.png"
                alt="feather mockup"
                className="header-mockup-1"
              />
            </div>
          </div>
        </div>
        <div className="section section-task-7">
          <div className="container">
            <div className="section-task-7-text">
              <h2>Overworks</h2>
              <h3>
                With our comprehensive overwork management solutions, you can
                navigate through intense workloads and overtime with ease. Make
                informed decisions quickly, ensuring seamless coordination and
                efficient resource allocation. Streamline your operations and
                conquer every deadline with confidence, all while maintaining a
                healthy work-life balance.
              </h3>
            </div>
            <div className="section-task-7-image">
              <div className="images">
                <img
                  src="images/feather/task/task_overworks.png"
                  alt="feather mockup"
                  className="header-mockup-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Task;
