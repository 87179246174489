import React, { useState } from 'react';
import ClientItem from './components/client_item';
import projects from './components/projects.json';

const Clients = () => {
  const [index, setIndex] = useState(0)

  const onChangeSlider = (action) => {
    if(action === '+') {
      let newIndex = index + 1
      if(newIndex + 1 > projects.length) setIndex(0)
      else setIndex(newIndex)
    }
    else {
      let newIndex = index - 1
      if (newIndex < 0) setIndex(projects.length - 1)
      else setIndex(newIndex)
    }
  }
  return (
    <div className="section section-landing-3">
      <h2 className="section-3-title">WE HELPED WITH</h2>
      <div className="section-hr"/>
      <div className="clients-holder">
        <div className="container">
          <section className="slider">
            <ClientItem project={projects[index]} onChangeSlider={onChangeSlider}/>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Clients
