import React, { useEffect } from 'react';
import renderParticles from '../../animation/particleWaves';

const Header = () => {
  function getInTouch () {
    window.scrollTo({
      top: 3300,
      behavior: "smooth"
    })
  }
  
  function nextSection () {
    window.scrollTo({
      top: 900,
      behavior: "smooth"
    })
  }

  useEffect (() => {
    renderParticles();
  }, [])

  return (
    <div className="section section-header">
      <h2 className="header-logo">PETTERSONAPPS</h2>
      <button className="button-default get-in-touch" onClick={getInTouch}>GET IN TOUCH</button>
      <div className="holder">
        <h2 className="holder-h2">HERE WE ARE</h2>
        <h1 className="holder-h1">TO DELIVER YOUR APP</h1>
        <button className="button-default" onClick={nextSection}>LEARN MORE</button>
      </div>
      <div id="surface" className="surface"></div>
      <div className="clearfix"></div>
    </div>
  )
}

export default Header
