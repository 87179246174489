import React from "react";
import "../stylesheets/feater/footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <div>
        <h3 className="footer-title">Get early access</h3>
        <img
          src="images/feather/footer/qrcode.png"
          alt="feather mockup"
          className="mockup-2"
        />
      </div>
    </div>
  );
};

export default Footer;
