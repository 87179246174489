import React from "react";

const CompanySystem = () => {
  return (
    <>
      <div id="section-system">
        <div className="section section-system-1">
          <img
            src="images/feather/companySystem/company-system-logo.svg"
            alt="employees logo icon"
            className="system1-icon"
          />
          <div className="first-text">
            <h2>Nervous system of your company</h2>
          </div>
          <div className="second-text">
            <h2>
              Welcome to the 'nervous system' of your organization, where
              exchange of impulses supersedes the traditional word-based
              communication. Unlike conventional CRM/ERP systems that rely
              heavily on text, our platform prioritizes swift and effective
              impulse exchanges. We're not just about words; we're about actions
              that drive productivity, efficiency, and success."
            </h2>
          </div>
        </div>
        <div className="section section-system-3">
          <div className="board-1">
            <div className="section-system-3-text">
              <h2>Control of deviations</h2>
              <h3>
                Maintain control with our proactive deviation monitoring system.
                Leveraging robust scripts and triggers, we identify and alert
                you to any deviations in real-time. The moment a trigger is
                activated, all relevant parties receive an instant notification,
                ensuring immediate feedback or status update. Experience peace
                of mind knowing that nothing slips through the cracks.
              </h3>
            </div>
          </div>
          <div className="board-2">
            <div className="section-system-3-text">
              <h2>Collaboration on the platform</h2>
              <h3>
                Embrace seamless collaboration on our all-in-one platform,
                designed to prevent potential deviations or resolve existing
                ones. Say goodbye to juggling multiple communication tools; with
                WIT, everything you need for effective collaboration is here,
                now. Collaborate with ease, streamline your work, and take
                control like never before.
              </h3>
            </div>
          </div>
        </div>
        <div className="section section-system-2">
          <div className="container">
            <div className="section-system-2-text">
              <h2>Board of deviations</h2>
              <h3>
                "Introducing our Board of Deviations - your one-stop solution
                for tracking all potential and existing deviations. In a
                visually appealing and intuitive format, we offer only the key
                information you need to understand the 'why' behind each
                deviation. Drawing on the effective principles of Agile boards,
                we've simplified the process even further for your convenience.
                Say hello to simplicity, clarity, and control.
              </h3>
            </div>
            <div className="section-system-2-image">
              <div className="images">
                <img
                  src="images/feather/companySystem/board.png"
                  alt="feather mockup"
                  className="header-mockup-1"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section section-system-2">
          <div className="container">
            <div className="section-system-2-text">
              <h2>Card of deviations</h2>
              <h3>
                Navigate the intricacies of each deviation with our
                comprehensive Deviation Cards. Consolidating all vital
                employee-related information needed to resolve the deviation, it
                showcases the entire interaction history, experience, and client
                specifics from the onset of the employee's tenure. With just one
                click, you can update statuses or access the most recent
                workload data. A world of information, now just a click away.
              </h3>
            </div>
            <div className="section-system-2-2-image">
              <div className="images">
                <img
                  src="images/feather/companySystem/deviations.png"
                  alt="feather mockup"
                  className="header-mockup-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanySystem;
