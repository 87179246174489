import React, { useState } from 'react';

export default function Facebook() {
  const [ hover, setHover ] = useState(false);
  function handleMouseOver() {
    setHover(true)
  }
  function handleMouseOut() {
    setHover(false)
  }
  return (
    <svg onMouseOver={handleMouseOver} className="svg" onMouseOut={handleMouseOut} viewBox="0 0 14 24" xmlns="http://www.w3.org/2000/svg"><g fill={hover ? "#42bc8e":"#bcc7cc"} fillRule="evenodd"><path d="m1246.33 1344.17h-3.808c-.451 0-.953.594-.953 1.383v2.75h4.764l-.72 3.922h-4.04v11.773h-4.494v-11.773h-4.077v-3.922h4.077v-2.307c0-3.309 2.296-5.999 5.447-5.999h3.808v4.172" transform="translate(-1233-1340)" fill={hover ? "#42bc8e":"#bcc7cc"}/></g></svg>
  )
}