import React from 'react';

import '../stylesheets/landing/header.css';
import '../stylesheets/landing/section-landing-2.css';
import '../stylesheets/landing/section-landing-3.css';
import '../stylesheets/landing/section-landing-4.css';
import '../stylesheets/landing/section-landing-5.css';
import '../stylesheets/landing/footer.css';

import Header from './header';
import GoodAt from './good_at';
import Clients from './clients';
import Logos from './logos';
import Map from './map';
import ContactUs from './contact_us';
import Footer from './footer';

const Landing = () => {
  return (
    <>
      <Header />
      <GoodAt />
      <Clients />
      <Logos />
      <Map />
      <ContactUs />
      <Footer />
    </>
  )
}

export default Landing