import React from 'react';

const GoodAt = () => {
  return (
    <div className="section section-landing-2" id="section">
      <h2 className="section-2-title">PEOPLE SAY WE ARE GOOD AT</h2>
      <div className="section-hr"></div>
      <div className="work-types-holder">
        <div className="work-type-item">
          <img src="images/landing/pa_web.svg" alt="alt icon" className="work-type-item-img"/>
          <h4 className="work-type-item-title">Front / Back-End</h4>
          <div className="work-type-item-text">
            Our team builds unique <br/> and functional web engines and sites
          </div>
        </div>
        <div className="work-type-item">
          <img src="images/landing/pa_mob.svg" alt="alt icon" className="work-type-item-img" style={{marginTop:'-8px'}} />
          <h4 className="work-type-item-title">App Development</h4>
          <div className="work-type-item-text">
            Perfect mobile solutions <br/> for any kind of needs and ideas
          </div>
        </div>
        <div className="work-type-item">
          <img src="images/landing/pa_des.svg" alt="alt icon" className="work-type-item-img"/>
          <h4 className="work-type-item-title">Look and Feel</h4>
          <div className="work-type-item-text">
            Qualified first class designers <br/> will make your product unforgettable
          </div>
        </div>
      </div>
    </div>
  )
}

export default GoodAt
