import Constants from '../constants/contact_us';
import axios from 'axios'

export function requestSubmitContact(form) {
  return async dispatch => {
    dispatch({
      type: Constants.SUBMIT_BUTTON_IS_LOADING
    });
    try {
      /* change url */
      await axios.post("https://us-central1-pettersonappsworkload.cloudfunctions.net/sendPartner", {
        name: form?.name,
        email: form?.email,
        phone: form?.phone,
        company: form?.company,
        message: form?.message,
      })
      return dispatch({
        type: Constants.SUBMIT_CONTACT,
        buttonState: "success",
        errors: {},
        submitted: true
      })
    } catch (err) {
      return dispatch({
        type: Constants.SUBMIT_CONTACT_FAILED,
        buttonState: "error",
        errors: form.errors,
        submitted: false
      })
    }
  }
}

export function handleButtonState(value) {
  return {
    type: Constants.CHANGE_BUTTON_STATE,
    buttonState: value
  }
}

export function handleChange(event) {
  return {
    type: Constants.CHANGE_CONTACT_FORM,
    form:{[event.target.name]:event.target.value}
  }
}
