import React from 'react';

const Map = () => {
  return (
    <div className="section section-landing-4">
      <h2 className="white">GET IN TOUCH WITH US</h2>
      <div className="section-hr main-theme"></div>
    </div>
  )
}

export default Map
