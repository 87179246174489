import React from 'react';
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { store } from './app/store/store'

import Landing from './app/components/landing';
import Feather from './app/components/feather';

function App() {
  return (
      <Router>
          <div className="App">
              <Provider store={store}>
                  <Switch>
                      <Route path='/' exact component={Landing} />
                      <Route path='/wit' component={Feather} />
                  </Switch>
              </Provider>
          </div>
      </Router>
  );
}

export default App;
