import Constants from '../constants/app';

let initialState = {
  isLoading: true
}

export default function (state = initialState, action) {
  switch(action.type) {
    case Constants.IS_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case Constants.NOT_LOADING:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}