import React from 'react';
import { ArrowRight, ArrowLeft } from '../../icons';

const ClientItem = ({ project, onChangeSlider }) => {
  return (
    <div className="client-item">
      <div className='slide--current'>
        <div className="slide__mover">
          <img src={project.bigImgUrl} alt="alt icon" className="client-item-img"/>
          <div className="client-item-description slide__title">
            <img src={project.smallImgUrl} alt="alt icon" className="client-item-description-logo" />
            <h2 className="client-item-description-title">
              { project.title }
            </h2>
            <p className="client-item-description-text">
              { project.description }
            </p>
              <div className="clearfix"></div>
            <button className="client-item-description-button">
              COMING SOON
              <span className="running-point">.</span>
              <span className="running-point">.</span>
              <span className="running-point">.</span>
            </button>
            <div className="client-controls">
              <nav className="slider__nav">
                <button className="button button--nav-prev" onClick={() => onChangeSlider('-')}>
                  <ArrowLeft />
                </button>
                <button className="button button--nav-next" onClick={() => onChangeSlider('+')}>
                  <ArrowRight />
                </button>
              </nav>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  )
}

export default ClientItem
