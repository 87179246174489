import React from 'react';
import { Facebook, LinkedIn, Instagram } from '../icons';

const Footer = () => {
  return (
    <footer className="section footer">
      <div className="footer-left">
        <img src="images/landing/img_logo_g.svg" alt="alt logo" className="footer-logo" />
        <span className="footer-copyright">© {new Date().getFullYear()} Petterson Apps, All rights reserved</span>
      </div>
      <div className="footer-contacts">
        <p>Uzhgorod, Shvabska Street, 66; Khmelnytskyi square, 2</p>
        <p>Drohobych, Zluka square, 1</p>
        <br/>
        <a href="mailto:info@pettersonapps.com" className="link">info@pettersonapps.com</a>
        <div className="contact-info-social" style={{marginTop:'2rem',marginBottom:'0'}}>
          <div className="contact-info-social-item" style={{marginLeft:0}}>
            <a href="https://www.facebook.com/pettersonapps.company/" target="_blank" rel="noreferrer noopener">
              <Facebook/>
            </a>
          </div>
          <div className="contact-info-social-item">
            <a href="https://www.linkedin.com/company/pettersonapps" target="_blank" rel="noreferrer noopener">
              <LinkedIn/>
            </a>
          </div>
          <div className="contact-info-social-item">
            <a href="https://www.instagram.com/petterson.apps/" target="_blank" rel="noreferrer noopener">
              <Instagram/>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
