import React from "react";

const Header = () => {
  return (
    <>
      <div id="section-header">
        <div className="section section-header-1">
          <div className="holder-feather">
            <img
              src="images/feather/header/laptop/wit_logo.svg"
              alt="feather logo"
              className="header-logo-feather"
            />
            <h2 className="holder-h2-feather">WIT</h2>
            <p className="holder-p-feather">by PettersonApps</p>
          </div>
        </div>

        <div className="section section-header-2">
          <div className="images">
            <img
              src="images/feather/header/laptop/laptop.png"
              alt="feather mockup"
              className="header-mockup-1"
            />
          </div>
        </div>

        <div className="section section-header-3">
          <div className="holder-feather-3">
            <p>
              Experience the pinnacle of convenience with our intuitive
              platform, designed to streamline your IT development team's
              management with unparalleled efficiency. Discover a comprehensive
              solution that effortlessly handles your most common processes,
              transforming the landscape of IT outsourcing operations and
              empowering you to conquer typical challenges with ease.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
