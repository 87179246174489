import React from "react";

const Employees2 = () => {
  return (
    <>
      <div id="section-employees2">
        <div className="section section-employees2-1">
          <img
            src="images/feather/employees/employees2_icon.svg"
            alt="employees logo icon"
            className="employees1-icon"
          />
          <div className="first-text">
            <h2>Client relationship management</h2>
          </div>
          <div className="second-text">
            <h2>
              Cultivating exceptional client relationships is our forte. With
              our comprehensive system, we have an in-depth understanding of
              each client's projects, the dedicated employees behind them, and
              the time and technology invested. Our teams, working meticulously
              on each project, are always in sync with their deadlines and can
              conveniently coordinate meetings, accommodating everyone's
              schedules. Here at PettersonApps, we ensure that your project
              isn't just another task; it's a mission we undertake together.
            </h2>
          </div>
        </div>
        <div className="section section-employees2-2">
          <div className="first-text">
            <h2>Client database</h2>
          </div>
          <div className="second-text">
            <h2>
              Discover the heart of our operations - our beloved clients, whom
              we truly know and appreciate. From understanding their fundamental
              needs to meticulously tracking their projects, the technologies
              used, the involved employees, and even the minutiae of our
              collaboration history - everything is neatly encapsulated in a
              visually appealing form. Our client database isn't just a
              repository of information; it's a testament to our commitment to
              personalized, thoughtful service.
            </h2>
          </div>
          <div className="section-employees2-2-image">
            <div className="images">
              <img
                src="images/feather/employees/client_database.png"
                alt="feather mockup"
                className="header-mockup-1"
              />
            </div>
          </div>
        </div>
        <div className="section section-employees2-3">
          <div className="section-employees2-3-text">
            <h2>Meeting management </h2>
            <h3>
              Making a meeting at right time and in right place has become even
              easier and more convenient.
            </h3>
          </div>
          <div className="images">
            <img
              src="images/feather/employees/employees_met_manage_1.png"
              alt="feather mockup"
              className="mockup-1"
            />
            <img
              src="images/feather/employees/employees_met_manage_2.png"
              alt="feather mockup"
              className="mockup-2"
            />
          </div>
        </div>
        <div className="section section-employees2-5">
          <div className="board-1">
            <div className="section-employees2-text">
              <h2>Management of Project Teems</h2>
              <h3>
                Experience the effortless orchestration of your project teams
                with WIT, regardless of their size. We understand the
                complexities that come with managing numerous employees and
                we've turned that challenge into an opportunity. With us,
                effective team management isn't just a promise; it's a
                certainty, no matter how many team members you have on board.
              </h3>
            </div>
          </div>
          <div className="board-2">
            <div className="section-employees2-text">
              <h2>Call always automatically updated resume</h2>
              <h3>
                Every task accomplished with success enhances the value of our
                employees, both to our organization and to our cherished
                clients. Here at WIT, we celebrate these achievements by
                generating vibrant CVs that reflect their growing competencies,
                making them not just assets for our company, but also attractive
                propositions for our clients.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Employees2;
