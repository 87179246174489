import React, { useState } from 'react';

export default function LinkedIn () {
  const [ hover, setHover ] = useState(false)
  function handleMouseOver() {
    setHover(true);
  }
  function handleMouseOut() {
    setHover(false);
  }
  return (
    <svg onMouseOver={handleMouseOver} className="svg" onMouseOut={handleMouseOut}  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill={hover ? "#42bc8e":"#bcc7cc"} fillRule="evenodd"><path d="m1308.33 1342.67c0 1.467-.933 2.667-2.667 2.667-1.6 0-2.667-1.2-2.667-2.533 0-1.467 1.067-2.8 2.667-2.8 1.6 0 2.667 1.2 2.667 2.667m-5.333 21.333h5.333v-17.333h-5.333v17.333m18.13-17.07c-2.8 0-4.4 1.6-5.067 2.667h-.133l-.267-2.267h-4.8c0 1.467.133 3.2.133 5.2v11.467h5.333v-9.467c0-.533 0-.933.133-1.333.4-.933 1.067-2.133 2.533-2.133 1.867 0 2.667 1.6 2.667 3.733v9.2h5.333v-9.867c0-4.933-2.533-7.2-5.867-7.2" transform="translate(-1303-1340)" fill={hover ? "#42bc8e":"#bcc7cc"}/></g></svg>
  )
}