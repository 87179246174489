import React from "react";

const Employees1 = () => {
  return (
    <>
      <div className="section section-employees-1">
        <img
          src="images/feather/employees/employees1_icon.svg"
          alt="employees logo icon"
          className="employees1-icon"
        />
        <div className="first-text">
          <h2>
            One of the main assets of any successful company is its
            <p>Employees</p>
          </h2>
        </div>
        <div className="second-text">
          <h2>
            Welcome to PettersonApps, a premier IT outsourcing company, uniting
            over a hundred exceptional professionals under one roof. As we
            continuously grow both in numbers and expertise, we prioritize
            employee well-being, recognizing that the perfect balance of work
            and life creates passionate professionals.
          </h2>
          <h2>
            Our innovative solution, WIT, is your ultimate tool for effortless
            process management, tailored to foster transparency and efficiency.
            We empower our clients with comprehensive insights into ongoing
            projects, our skilled workforce, and our valued customers.
          </h2>
          <h2>
            With WIT, gain absolute clarity on every aspect of your operations.
            Monitor the time individual team members devote to each project,
            understand the technologies they utilize, track vacations,
            overtimes, and more.
          </h2>
          <h2>
            But our vision transcends the tactical—within Workload, you can also
            explore how your on-ground processes align with your corporate
            culture. Join us on this transformative journey, where processes
            meet values seamlessly.
          </h2>
        </div>
      </div>
    </>
  );
};

export default Employees1;
