import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    padding: '1.2rem 0.15em 0',
    maxWidth: '250px',
    width: '100%',
    background: 'transparent',
    color: '#595f6e',
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: '652px',
      width: '100%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: '652px',
      width: '100%'
    },
  },
  textarea: {
    maxWidth: '550px',
    width: '100%',
    maxLength: "1000",
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: '652px',
      width: '100%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: '652px',
      width: '100%'
    },
  },
  circleLoading: {
    color: '#42bc8e',
    marginTop: "5px"
  },
  closeIcon: {
    fontSize: '40px'
  },
  doneIcon: {
    fontSize: '40px'
  },
  wrapper: {
    position: 'relative',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: '30px'
    }
  },
  success: {
    marginTop: "25px",
    background: '#42bc8e',
    border: '2px solid #42bc8e',
    color: 'white',
    fontSize: '16px',
    borderRadius: '27px',
    cursor: 'pointer',
    textDecoration: 'none',
    textAlign: 'center',
    display: 'inline-block',
    width: '180px',
    height: '54px',
    transition: 'all 0.3s linear',
    alignSelf: 'flex-end',
    '&:hover': {
      backgroundColor: "#fff",
      color: "#42bc8e"
    },
  },
  loading: {
    marginTop: "25px",
    background: 'white',
    border: '2px solid #42bc8e',
    color: 'white',
    borderRadius: '27px',
    cursor: 'pointer',
    textDecoration: 'none',
    textAlign: 'center',
    display: 'inline-block',
    width: '180px',
    height: '54px',
    transition: 'all 0.3s linear',
    alignSelf: 'flex-end',
    '&:hover': {
      backgroundColor: "#fff",
      color: "#42bc8e"
    },
  },
  error: {
    marginTop: "25px",
    background: 'red',
    border: '2px solid red',
    color: 'white',
    borderRadius: '27px',
    cursor: 'pointer',
    textDecoration: 'none',
    textAlign: 'center',
    display: 'inline-block',
    width: '180px',
    height: '54px',
    transition: 'all 0.3s linear',
    alignSelf: 'flex-end',
    '&:hover': {
      backgroundColor: "#fff",
      color: "red"
    },
  }
}))

export default useStyles