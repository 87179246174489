import Constants from '../constants/contact_us';

let initialState = {
  name: '',
  email: '',
  phone: '',
  company: '',
  message: '',
  buttonState: '',
  errors: {},
  submitted: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.SUBMIT_CONTACT:
      return {
        ...state,
        buttonState: action.buttonState,
        errors: action.errors,
        submitted: action.submitted,
        name: '',
        email: '',
        phone: '',
        company: '',
        message: ''
      }
    case Constants.SUBMIT_CONTACT_FAILED:
      return {
        ...state,
        buttonState: action.buttonState,
        errors: action.errors,
        submitted: action.submitted
      }
    case Constants.SUBMIT_BUTTON_IS_LOADING:
      return {
        ...state,
        buttonState: 'loading'
      }
    case Constants.CHANGE_BUTTON_STATE:
      return {
        ...state,
        buttonState: action.buttonState
      }
    case Constants.CHANGE_CONTACT_FORM:
      return {
        ...state,
        ...action.form,
        errors: {...state.errors, [Object.keys(action.form)[0]]:false }
      }
    default:
      return state;
    }
}